import React, { Component } from 'react';
import './pregunta.css'
import { Col, Row } from 'react-bootstrap';


class Pregunta extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <Row className='m-0 p-0 RowPre w-100'>
            <div className="IniPre"></div>
                <Col className='m-0 p-0 ColPre'>
                    <h3  className='m-0 p-0 h3Pre'>Q&Q – Archive of Questions</h3>

                    <label className=' labelPre1'>
                    Q&Q is a medium to explore broader questions about my
                    creative practice through certain titles which most
                    interest me. I’ve always thought that the most extraordinary 
                    results can be found in between things, and this is
                    what I try to explore in this space. Work is the medium of
                    exploring? Indeed. 
                    </label>

                    <h3  className='m-0 p-0 h3Pre'>Q1 Experimental</h3>


                    <label>
                    <label className='subrayadoPre'>How do I exercise lateral thinking?</label> 
                    <label className=' labelPre'>I’m constantly trying to push my work into new territories and
                    that is why my process-driven practice celebrates experimentation 
                    by testing the limits of both analogue and digital tools, so as
                    to never get stuck on past achievements. Self-initiated projects
                    are a great way to exercise and challenge my practice by
                    employing new techniques, so I might scout out uncomfortable
                    routes. That is why I believe that I’m always under construction:
                    I’m always open to learning from different fields, personalities,
                    cultures and anything with which I cross paths. In my experience, 
                    exploring a wide spectrum of disciplines has allowed me
                    to understand that failure is part of the game. Without risks, you
                    get safe design. Without being open to experience or vulnerability 
                    to what is in front of you, there can be no change. I’m now
                    recalling something I once read about the learning processes:
                    “Learning is not easy, because it puts us in front of our own
                    limitations”. Embracing new pathways can sometimes be
                    overwhelming, but will invariably offer rewards.</label>
                    </label>

                    <h3  className='m-0 p-0 h3Pre'>Q2 Process</h3>


                    <label>
                    <label className='subrayadoPre'>What is my Step-by-Step Process?</label> 
                    <label className=' labelPre'>My approach to virtually every project has three steps. 
                    My first
                    step is the Concept-Driven Work, which encompasses the tools
                    to develop the project. The initial strategy and concept phase
                    will help us identify the brand’s voice, find its weirdness, and see
                    how we can position it uniquely in its market across multiple
                    mediums. Next comes Art Direction, which is where we take into
                    account the tools developed in the first step and begin to form
                    preliminary thoughts. Lastly, we have Project Completion. Once
                    we have identified the quintessence of the project, we know
                    what we want to communicate and it becomes easier to “play”
                    with the system in order to complete it without recurring
                    constantly to the same graphical decisions. Besides, documenting 
                    every step of the design process - from initial sketches to
                    final design - is key to understand the methodology behind my
                    work. Observing the paths travelled allows me to detect the
                    obsessions, the predominant ideas, the conventional canons
                    and cultural matrices in which we are all immersed.</label>
                    </label>
                </Col>

                <Col className='m-0 p-0 ColPre'>

                <h3  className='m-0 p-0 h3Pre'>Q3 Conceptual Thinking</h3>

                <label>
                <label className='subrayadoPre'>How do I do what I do?</label> 
                <label className=' labelPre'>I always start the design process from a concept, an idea
                connected to other ideas and it may be generated by the meeting of a 
                word with an object, a movie, a moment in life or even an
                entire culture. I continuously cross over the distinct boundaries
                set in graphic design, giving value to illogical ideas in order to
                find what to say. I must say that growing up surrounded by
                scientific minds has enabled me to always be curious, investigate 
                and to fully immerse myself in the projects I participate in. </label>
                </label>

                <h3  className='m-0 p-0 h3Pre'>Q4 Questioning</h3>

                <label>
                <label className='subrayadoPre'>How to prevent falling into a familiar outcome?</label> 
                <label className=' labelPre'>I truly believe that one of the most important things to keep in
                    mind while approaching a project is not to go with the first thing
                    that comes to mind. I understand each work as an opportunity to
                    break down the boundaries of graphic design, without recurring
                    to any well-worn paths in order to diminish risks. Putting in crisis
                    common places is something that cannot be missed in any
                    project I face, and that is why working with ambitious people is
                    so important: it reassures me that we both will give everything to
                    accomplish an extraordinary result. As a matter of fact, before
                    starting any professional relationship, I need to know who is in
                    front of me. Respect and trust are the basic rules. But what I
                    really need to understand is the way of thinking of the other
                    person, understanding their points of view
                    and finding out if we could really work together. Actually, this is
                    the path I always lead any client to go through. I’m not interested
                    in projects which don’t have an idea, intention or goal. Finding
                    oneself beside another with the same level of determination and
                    enthusiasm makes a project more than a simple project. It
                    creates, without a shadow of a doubt, a distinctive character
                    with an original language, developed in a collaborative environment 
                    where the creative process is as enjoyable as the result.</label>
                </label>

                <h3  className='m-0 p-0 h3Pre'>Q5 Out of the computer</h3>

                <label>
                <label className='subrayadoPre'>Out of the computer or get stuck behind it?</label> 
                <label className=' labelPre'>It is my view that computers are a medium for achieving specific
                goals. I’m not really into them when I’m developing a concept,
                nor investigating or searching out inspiration. Forgetting for a
                moment our current pandemic situation, I prefer to go for a cup
                of coffee and discuss face to face ideas, write with a (real) pencil
                keywords and sketches. Yes, I do have a notepad and I’m not
                planning to throw it away!) Not to mention that I’m always with
                camera in hand, because I really enjoy finding inspiration while
                walking the streets. That being said, I’m not averse to computers, 
                but I do like to be aware of when I’m using them.</label>
                </label>

                </Col>
                <Col className='m-0 p-0 ColPre'>

                <h3  className='m-0 p-0 h3Pre'>Q6 In-between </h3>

                <label>
                <label className='subrayadoPre'>Extraordinary or ordinary?</label> 
                <label className=' labelPre'>I once had an Italian teacher who was also a filmmaker. First she
                studied cinema and then languages. I cannot think of another
                example for this question; her classes were a mix of cinema,
                language and history. I was learning a language through another
                profession. Patricia illustrates why having diverse interests,
                professions, knowledge and thoughts is so important. She made
                me realize that in-between things are that little extra. When we
                can cross diverse intentions, professions or ideas, the result
                tends to be richer in content. This applies not only to people but
                also to projects. For example, if I’m working in an architectural
                project and I know nothing about where it comes from, it’s not
                enough. If we want to discover a unique voice, I must know
                peculiarities beyond the project itself. What kind of cinema does
                it represent? What about art? If you have to choose a decade to
                talk about the project, what would it be? Questions goes on.</label>
                </label>

                <h3  className='m-0 p-0 h3Pre'>Q7 Multidisciplinary</h3>

                <label>
                <label className='subrayadoPre'>How important is It to work in a multidisciplinary context?</label> 
                <label className=' labelPre'>Working with people from diverse backgrounds as varied as
                    graphic design, filmmaking, animation, amongst others, is key to
                    enriching our thinking and to ignite the spark of innovation
                    as we move forward in our career. In my view, this working
                    method encourages independent thinking alongside a collaborative 
                    effort among creatives. Not to mention that it produces new
                    ways of utilizing ideas and solutions that result in outcomes that
                    would not arise from mono-disciplinary approaches.</label>
                </label>

                <h3  className='m-0 p-0 h3Pre'>Q8 Collaborative</h3>

                <label>
                <label className='subrayadoPre'>Solo Projects or Collaborations?</label> 
                <label className=' labelPre'>Collaborations are the best way to achieve distinctive results. I
                    believe that the best projects in which I participated were so
                    because I work with other people, who may or may not be from
                    my specific field. This is closely related to why I love teaching
                    (but I’ll develop this item in another question). I love to work with
                    motion designers, product designers, web developers, architects
                    or even with professionals who are not related to arts. We can all
                    say what we think, compare thoughts and find a solution for
                    specific problems by gathering a list of ideas together. It is not
                    only a great way to achieve something smart and unique - since
                    we all put on the table our knowledge - but also to learn and
                    move forward in life aided by some small part of another’s
                    knowledge. That being said, when I do solo works, I try to make
                    it as collaborative as possible. I don’t want to just deliver a
                    design: I seek my client’s participation and always exchange
                    points of view to reach more interesting and incredible results.</label>
                </label>

                </Col>

                <Col className='m-0 p-0 ColPre'>

                <h3  className='m-0 p-0 h3Pre'>Q9 Education</h3>

                <label>
                <label className='subrayadoPre'>Doubt is better than certainty?</label> 
                <label className=' labelPre'>This is something I continuously keep in mind as a teaching
                professor and leader of multiple teams. I earned my degree at
                the University of Argentina, where I’ve been a teaching professor
                with more than 60 students in my charge. I teach what I exercise
                everyday. My assignment is not about formal abilities, but rather
                involves encouraging students to think critically and experiment
                fearlessly. I’m interested in a more horizontal education focused
                on communication, tolerance and self-doubt. Moreover, the fact
                that it is a public university makes it unique, because it means
                that there are multiple and diverse voices, which allow students
                to connect with people they might otherwise never have met and
                where the outstanding value is the plurality of voices. Being a
                teaching professor gave me the possibility to bring my own
                perspectives on education with a pre-established work team.
                Among my contributions have been the creation of new projects
                for the course and multiple ways of approaching our students.
                This brought a positive change because we grew closer to our
                students and their work process.</label>
                </label>

                <h3  className='m-0 p-0 h3Pre'>Q10 Methodology </h3>

                <label>
                <label className='subrayadoPre'>Being methodological mean following a formula?</label> 
                <label className=' labelPre'>
                No. The idea of a clear design process feels oppressive to me. I
                experience my work as a design journey. The projects come with
                specific questions that have to be answered, but there’s also a
                personal search that eventually connects them all. Besides,
                following a formula would mean moving along a specific and
                already path traveled toward a goal which had previously been
                reached. My step-by-step process is more like the idea of a
                continent. Initially, only the basic characteristics are known. After
                putting a foot on that continent, it can take a while but eventually
                I can make out the country in which I've disembarked. In that
                first country I discover a connection to another country, which is
                different in nature. Although all countries have their own specific
                flavour and character, they are all connected to each other. The
                countries are my individual projects, and they conform my entire
                body of work.</label>
                </label>

                <h3  className='m-0 p-0 h3Pre'>Q11 Intercultural </h3>

                <label>
                <label className='subrayadoPre'>How important is it to build intercultural relationships?</label> 
                <label className=' labelPre'>Working with a studio that wasn’t in my country for more than 4
                years was essential to break down cultural barriers and use them
                in favor of a better interaction with teams and clients from
                different cultures. Now as my own creative director, I find
                cross-cultural awareness crucial to moving our work towards a
                much more diverse, emphatic and inclusive vision.</label>
                </label>

                </Col>
            </Row>
        );
    }
}
 
export default Pregunta;