import React from "react";
import './pagenotfound.css';

const PageNotFound = () => (
//Fragment
  <div >
  <div class="error-container">
  <h1>404</h1>

  <p class="return">Take me back to <a href="/">Julia</a></p>
</div>
		{/* Mensaje cuando llegue a página incorrecta */}
    
  </div>
)
export default PageNotFound